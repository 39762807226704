<template>
  <div class="download-game">
    <img :src="'/button_down.png'" @click="downloadGame" alt="下载游戏按钮" class="download-button">
  </div>
</template>

<script>
export default {
  name: 'DownloadGame',
  methods: {
    downloadGame() {
      window.location.href = 'https://resource.cdbtgame.com/app/android/zhanjian.apk'; // 替换为实际的下载链接
    }
  }
}
</script>



<style scoped>

.download-game {
  background-image: url('@/assets/zhanjian.jpg');
  background-size: cover;
  /* 确保背景图片覆盖整个区域 */
  background-position: center;
  background-repeat: no-repeat;
  /* 防止背景图片重复 */
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  /* 防止溢出 */
}

.download-button {
  width: 80%;
  max-width: 200px;
  cursor: pointer;
  margin-top: 140%;
}

@media (max-width: 768px) {
  .download-game {
    padding: 10px;
  }
 .download-button {
   width: 90%;
   max-width: 200px;
   margin-top: 140%;
 }
}
</style>